@import '../../assets/base';

.send-feedback-screenshot {
    background-color: $gray-200;
    border: 2px solid $gray-500;
    min-height: 120px;
    position: relative;
    img {
        max-width: 100%;
    }
    .select-edit-mode {
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 100px);
        height: 100px;
        width: 200px;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        border-radius: 6px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            .text-primary {
                color: #fff !important;
            }
        }
        i {
            font-size: 32px;
        }
    }
}

.overlay-screenshot-mode {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 1049;
    cursor: crosshair;
    .highlighted-item {
        position: absolute;
        border: 2px solid $yellow;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.4);

        .remove {
            display: none;
            padding: 3px;
            i {
                font-size: 18px;
                font-weight: bold;
                color: #c6c6c6;
                cursor: pointer;
                border-radius: 50%;
                background: #fff;
            }
        }

        &.sensitiveInfo {
            background-color: $black;
            border: none;
            border-radius: 0;
            .remove {
                i {
                    border-radius: 0;
                    background: transparent;
                }
            }
        }

        &:hover .remove {
            display: block;
        }
    }
}

.box-highlight-bar {
    width: 232px;
    height: 56px;
    align-items: center;
    background-color: white;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
        rgba(0, 0, 0, 0.12) 0px 9px 46px 8px,
        rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
    cursor: pointer;
    flex-direction: row;
    pointer-events: auto;
    z-index: 1051;
    position: relative;
    .highlight-issue,
    .hide-sensitive-info {
        height: 56px;
        width: 56px;
        padding: 10px;
        .color {
            height: 36px;
            width: 36px;
            display: block;
        }

        &.active {
            .color {
                border: 3px solid $gray-500;
            }
        }
    }

    .highlight-issue {
        .color {
            background-color: $yellow;
        }
    }

    .hide-sensitive-info {
        .color {
            background-color: $black;
        }
    }

    .handle {
        padding: 0 5px 0 10px;
        font-size: 16px;
    }

    .highlight-done {
        cursor: pointer;
        padding: 0px 8px;
        color: #4285f4;
        pointer-events: auto;
        width: 100px;
        text-align: center;
    }
}

body.edit-screenshot-mode {
    overflow: hidden;
    .overlay-screenshot-mode {
        display: block;
    }
}

.send-feedback-icon {
    font-size: 36px;
}
