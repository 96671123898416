@import 'themes';
.app-header__logo .logo-src {
    height: 24px;
    width: 151px;
    background: url('images/logo-inverse.png');
}

.app-header.header-text-light .app-header__logo .logo-src {
    background: url('images/logo.png');
}

.app-header.header-text-dark .app-header__logo .logo-src {
    background: url('images/logo-inverse.png');
}

.app-sidebar.sidebar-text-light .app-header__logo .logo-src {
    background: url('images/logo.png');
}

.app-container {
    @include themify($themes) {
        color: themed('textColor');
        background-color: themed('backgroundColor');
    }
}
.text-muted {
    @include themify($themes) {
        color: themed('textMuted') !important;
    }
}
.card-header,
.card-footer {
    @include themify($themes) {
        background-color: themed('bgCard');
        color: themed('textColor');
    }
}
.card-title {
    @include themify($themes) {
        color: themed('textColor');
    }
}
.card {
    @include themify($themes) {
        color: themed('textColor');
        background-color: themed('bgCard');
    }

    .list-group-item-action {
        @include themify($themes) {
            color: themed('textColor');
            background-color: themed('bgHighLight');
            &:hover,
            &:focus {
                background-color: themed('bgCard');
            }
        }
    }
    .list-group-item {
        @include themify($themes) {
            background-color: themed('bgHighLight');
        }
    }
}
.inline-field .label-wrapper.text-muted {
    @include themify($themes) {
        color: themed('textMuted') !important;
    }
}
.settings-breadcrumb-wrapper .breadcrumb {
    @include themify($themes) {
        background-color: themed('bgHighLight');
        a {
            color: themed('linkColor');
        }
        .breadcrumb-item.active {
            color: themed('breadcrumbActive');
        }
    }
}
.rst__rowContents {
    @include themify($themes) {
        background-color: themed('bgHighLight');
    }
}
td {
    @include themify($themes) {
        color: themed('tdColor');
        a {
            color: themed('linkColor');
            &:hover {
                color: themed('linkHoverColor');
            }
        }
    }
}
.table-hover tbody tr:hover {
    @include themify($themes) {
        background-color: themed('bgHighLight');
    }
}
.dropdown-item {
    @include themify($themes) {
        color: themed('linkColor');
        &:hover,
        &:focus {
            color: themed('linkHoverColor');
        }
    }
}
.select-extension {
    @include themify($themes) {
        div {
            background-color: themed('bgHighLight');
            color: themed('textColor');
        }
    }
}
.text-primary {
    @include themify($themes) {
        color: themed('textPrimary') !important;
    }
}
.btn-outline-secondary {
    @include themify($themes) {
        color: themed('btnOutlineSecondaryColor');
        border-color: themed('btnOutlineSecondaryColor');
        &:hover,
        &:focus {
            color: themed('btnOutlineSecondaryHover');
            border-color: themed('btnOutlineSecondaryColor');
            background-color: themed('btnOutlineSecondaryColor');
        }
    }
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    @include themify($themes) {
        color: themed('btnOutlineSecondaryActiveColor');
        border-color: themed('btnOutlineSecondaryActive');
        background-color: themed('btnOutlineSecondaryActive');
    }
}
.btn-outline-primary {
    @include themify($themes) {
        color: themed('btnOutlinePrimaryColor') !important;
        border-color: themed('btnOutlinePrimaryBorder') !important;
        &:hover {
            color: themed('btnOutlinePrimaryHover') !important;
            border-color: themed('btnOutlinePrimaryColor') !important;
            background-color: themed('btnOutlinePrimaryColor') !important;
        }
    }
}

.btn-primary {
    @include themify($themes) {
        color: themed('btnOutlinePrimaryColor');
        background-color: themed('btnPrimaryBackgroundColor');
        border-color: themed('btnPrimaryBorderColor');
        &:hover {
            color: themed('btnPrimaryBackgroundColor') !important;
            border-color: themed('btnOutlinePrimaryColor') !important;
            background-color: themed('btnOutlinePrimaryColor') !important;
        }
    }
}
.btn-link {
    @include themify($themes) {
        color: themed('linkColor');
        &:hover,
        &:focus {
            color: themed('linkHoverColor');
        }
    }
}
.dataTables_wrapper .btn-secondary {
    @include themify($themes) {
        color: themed('textSecondary');
    }
}
.badge-dark {
    @include themify($themes) {
        color: themed('badgeDarkColor');
        background-color: themed('badgeDarkBackgroundColor');
    }
}
.modal-content,
.modal-header,
.modal-footer {
    @include themify($themes) {
        background-color: themed('backgroundColor');
        color: themed('textColor');
    }
}

.close {
    @include themify($themes) {
        color: themed('textMuted');
    }
}
