$themes: (
    dark: (
        textColor: #fff,
        textMuted: #eee,
        breadcrumbActive: #b1b1b1,
        backgroundColor: #000,
        linkColor: rgb(239, 236, 255),
        logo: 'logo.png',
        logoInverse: 'logo-inverse.png',
        bgHighLight: #536575,
        textHoverColor: rgb(201, 199, 199),
        bgDanger: #d4d4d4,
        bgHamburger: #ffffff,
        bgCard: #242831,
        tabSelectedColor: #51b1fe,
        bgNightSky:
            linear-gradient(to top, #a9bee4 0%, #a9bee4 1%, #747d8e 100%),
        btnPrimary: #a9bee4,
        linkHoverColor: #60a2e0,
        tdColor: #e3e3e3,
        textPrimary: #545cd8,
        textSecondary: #ddd,
        btnOutlinePrimaryColor: #f9f9f9,
        btnOutlinePrimaryBorder: #fefefe,
        btnOutlinePrimaryHover: #000,
        btnPrimaryBackgroundColor: #424242,
        btnPrimaryBorderColor: #424242,
        btnOutlineSecondaryColor: #aaa,
        btnOutlineSecondaryHover: #555,
        btnOutlinePrimaryShadow: rgba(246, 246, 247, 0.5),
        btnLight: #ffffff,
        btnLightActive: #f1f1f1,
        dashHeader: #3d424b,
        widgetMain: #242831,
        scrollbarTrack: #555,
        scrollbarThumb: #919191,
        scrollbarThumbHover: #878787,
        widgetDropdownItem: #636c75,
        widgetDropdownItemBorder: #bdbdbd,
        apexchartsIcon: #e3e3e3,
        apexchartsTooltip: #000,
        reactCalendar: #343a40,
        calendarTile: #eee,
        calendarTileHover: #555,
        weekend: #f73e3e,
        tileNow: #006edc,
        tileNowText: #ffffff,
        tileActive: #00dcac,
        tileActiveText: #fff,
        calendarButton: #fff,
        calendarButtonHover: #555,
        calendarButtonHoverText: #000,
        badgeDarkColor: #fff,
        badgeDarkBackgroundColor: #343a40,
        btnOutlineSecondaryActiveColor: #555,
        btnOutlineSecondaryActive: #aaa
    ),
    blue: (
        textColor: #fff,
        textMuted: #eee,
        breadcrumbActive: #b1b1b1,
        backgroundColor: #0044ff,
        linkColor: #3ed2ff,
        logo: 'logo.png',
        logoInverse: 'logo-inverse.png',
        bgHighLight: #0f18a0,
        textHoverColor: rgb(201, 199, 199),
        bgDanger: #d4d4d4,
        bgHamburger: #ffffff,
        bgCard: #143ab5,
        tabSelectedColor: #00c3ff,
        bgNightSky:
            linear-gradient(to top, #a9bee4 0%, #a9bee4 1%, #747d8e 100%),
        btnPrimary: #76adff,
        linkHoverColor: #60a2e0,
        tdColor: #e3e3e3,
        textPrimary: #76adff,
        textSecondary: #ddd,
        btnOutlinePrimaryColor: #f9f9f9,
        btnOutlinePrimaryBorder: #fefefe,
        btnOutlinePrimaryHover: #0f18a0,
        btnPrimaryBackgroundColor: #0f18a0,
        btnPrimaryBorderColor: #0f18a0,
        btnOutlineSecondaryColor: #1487b5,
        btnOutlineSecondaryHover: #173080,
        btnOutlinePrimaryShadow: rgba(246, 246, 247, 0.5),
        btnLight: #ffffff,
        btnLightActive: #f1f1f1,
        dashHeader: #143ab5,
        widgetMain: #143ab5,
        scrollbarTrack: rgb(10, 1, 143),
        scrollbarThumb: #0327f0,
        scrollbarThumbHover: #02085e,
        widgetDropdownItem: #0028ac,
        widgetDropdownItemBorder: #b8bdd8,
        apexchartsIcon: #e3e3e3,
        apexchartsTooltip: #0044ff,
        reactCalendar: #0c45ff,
        calendarTile: #eee,
        calendarTileHover: #02236d,
        weekend: #f59c9c,
        tileNow: #f9a60e,
        tileNowText: #0000ff,
        tileActive: #c77b09,
        tileActiveText: #eee,
        calendarButton: #fff,
        calendarButtonHover: #02085e,
        calendarButtonHoverText: #ccc,
        badgeDarkColor: #fff,
        badgeDarkBackgroundColor: #02085e,
        btnOutlineSecondaryActiveColor: #fff,
        btnOutlineSecondaryActive: #1487b5
    )
);
@mixin themify($themes: $themes) {
    @each $theme, $map in $themes {
        body.#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value
                    )
                ) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}
@function themed($key) {
    @return map-get($theme-map, $key);
}
